<!--
 * @Description:支付选择 choosePayStyle
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-04-10 10:23:17
 * @LastEditors: zhoucheng
-->
<template>
  <div class="body">
    <van-nav-bar title="支付选择"
                 left-text="返回"
                 left-arrow
                 @click-left="handleClickTopBack" />
    <van-row class="top-text">
      您的车辆离场15分钟后，如果发现订单仍未终止，可能是网络延迟等原因导致，请等订单终止后再支付。如您确定已完成缴费，请勿重复支付。谢谢!
    </van-row>
    <div class="content">
      <div class="content-text">
        <span>应付金额</span>
      </div>
      <div class="content-number">
        <span class="content-number-number">{{ needPay }}</span>
        <span>元</span>
      </div>
      <div class="paystyle-text">
        <span>支付方式</span>
      </div>
      <div class="weixin">
        <div class="imgbox">
          <img class="paymentStyle-img"
               src="@/assets/wxParForParking/weixin.png"
               alt="" />
        </div>
        <span class="paymentStyle-text">微信支付</span>
        <span class="paymentStyle-check">
          <van-radio-group v-model="radio">
            <van-radio name="WX"></van-radio>
          </van-radio-group>
        </span>
      </div>
    </div>
    <van-button class="commitPay"
                :loading="commitLoading"
                loading-text="正在支付..."
                :disabled=disabled
                @click="handleCommitPay">确认支付</van-button>
  </div>
</template>

<script>
import wx from 'weixin-jsapi'
import $wxConfig from '@/utils/wxConfig.js'
export default {
  data () {
    return {
      commitLoading: false,
      needPay: 0, // 应付金额
      radio: 'WX',
      nonceStr: '', // 随机串
      timeStamp: '', // 时间戳
      sign: '', // 签名
      disabled: false,
      plateNumber: '',
      numberPlateColorCode: ''
    }
  },
  created () {
    this.needPay = this.$route.query.payMoney

    if (typeof this.$route.query.plateNumber !== 'undefined') {
      sessionStorage.setItem('plateNumber', this.$route.query.plateNumber)
      sessionStorage.setItem('numberPlateColorCode', this.$route.query.numberPlateColorCode)
    } else {
      this.$router.push('/parkPayForOthers')
    }

    this.queryOrderStatus()
  },
  mounted () { },
  methods: {
    // 根据订单获取订单状态
    queryOrderStatus () {
      if (this.$route.query.orderIds) {
        // 从合单 || 欠费合单 来的
        this.$route.query.orderIds.forEach(item => {
          const info = {
            orderSequence: item
          }
          this.$commonPage.chargeBill(info).then(response => {
            // 在停 已交费
            if (response.resultEntity.leaveTime === null && response.resultEntity.orderStatusCode === 2) {
              this.disabled = true
              this.$toast('在停订单已支付，请后退后重新识别')
            }
          })
        })
      } else {
        // 不从合单来的
        // 如果是从欠费页面来的 不需要查询在线订单
        if (this.$route.query.page !== 'lackOrder') {
          const info = {
            plateNumber: sessionStorage.getItem('plateNumber'),
            numberPlateColorCode: sessionStorage.getItem('numberPlateColorCode')
          }
          this.$queryDict.queryOnlineOrderBySinglePlateNumber(info).then(res => {
            if (res.resultEntity.orderStatusCode === 2) {
              this.disabled = true
              this.$toast('订单已支付，请后退后重新识别')
            }
          })
        }
      }
    },
    // 查询车牌号有无欠费订单
    queryArrangeOrder () {
      const info = {
        plateNumber: sessionStorage.getItem('plateNumber'),
        numberPlateColorCode: sessionStorage.getItem('numberPlateColorCode')
      }
      this.$lackOrder.queryArrangeOrderByPlateCode(info).then(res => {
        if (res.resultEntity.order.length > 0) {
          // 有欠费
          this.$router.push({
            name: 'lackOrder',
            query: {
              plateNumber: sessionStorage.getItem('plateNumber'),
              numberPlateColorCode: sessionStorage.getItem('numberPlateColorCode')
            }
          })
        } else {
          const that = this
          // 无欠费
          if (that.$route.query.page === 'lackOrder') {
            // 从欠费页面进入
            that.$router.push('/parkPayForOthers')
          } else if (that.$route.query.page !== 'lackOrder') {
            // 不是从欠费页面进入
            that.$router.push('/parkPayForOthers')
          }
        }
      })
    },
    // 确认支付按钮
    handleCommitPay () {
      this.commitLoading = true
      const info = {
        userCode: JSON.parse(sessionStorage.getItem('userInfo')).userCode,
        type: '2'
      }
      // 调起微信支付
      this.$selfInfo.getPayId(info).then(res => {
        if (this.$route.query.style === '补缴') {
          const info1 = {
            openid: res.resultEntity[0].payId,
            orderIds: this.$route.query.orderIds,
            description: '微信支付'
          }
          this.$queryDict.jsApiPayBatch(info1).then(res => {
            const timestamp = res.resultEntity.timeStamp
            const nonceStr = res.resultEntity.nonceStr
            const packages = res.resultEntity.package
            const signType = res.resultEntity.signType
            const paySign = res.resultEntity.paySign
            this.wxPay(timestamp, nonceStr, packages, signType, paySign)
          }).catch(() => {
            this.commitLoading = false
          })
        } else {
          const info1 = {
            openid: res.resultEntity[0].payId,
            orderSequence: this.$route.query.payOrder,
            amount: Number(this.needPay) * 100,
            description: '微信支付'
          }
          this.$payForParking.wxAppPay(info1).then(res => {
            // 金额异常
            if (res.resultCode === '4001') {
              this.$router.go(-1)
            } else {
              const timestamp = res.resultEntity.timeStamp
              const nonceStr = res.resultEntity.nonceStr
              const packages = res.resultEntity.package
              const signType = res.resultEntity.signType
              const paySign = res.resultEntity.paySign
              this.wxPay(timestamp, nonceStr, packages, signType, paySign)
            }
          }).catch(() => {
            this.commitLoading = false
          })
        }
      }).catch(err => {
        this.commitLoading = false
        console.log('支付失败', err)
      })
    },
    // 发起微信支付请求
    wxPay (timestamp, nonceStr, packages, signType, paySign) {
      const self = this
      $wxConfig(['chooseWXPay']).then(() => {
        this.commitLoading = false
        wx.chooseWXPay({
          timestamp: Number(timestamp), // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
          nonceStr, // 支付签名随机串，不长于 32 位
          package: packages, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
          signType, // 微信支付V3的传入RSA,微信支付V2的传入格式与V2统一下单的签名格式保持一致
          paySign, // 支付签名
          success: function () {
            self.$toast.success('支付成功')
            // self.$router.push('/parkPayForOthers')
            self.queryArrangeOrder()
          },
          error: function () {
            self.$toast.fail('支付失败')
            self.commitLoading = false
            self.$router.go(-1)
          }
        })
      }).catch(err => {
        console.log('签名配置失败', err)
        this.commitLoading = false
      })
    },

    // 点击返回事件
    handleClickTopBack () {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="less" scoped>
.body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  position: relative;
  .top-text {
    padding: 10px;
    font-size: 14px;
    background: #ffb542 30%;
    color: #a47533;
  }
}
.content {
  background: #ffffff;
  border-radius: 6px;
  width: 94%;
  margin-left: 3%;
  height: 205px;
  margin-top: 10px;
  padding-top: 35px;
}
.content-text {
  font-family: PingFangSC-Regular;
  font-size: 16px;
  color: #666666;
  letter-spacing: -0.48px;
  width: 100%;
  height: 21px;
  line-height: 21px;
  text-align: center;
}
.content-number {
  width: 100%;
  height: 40px;
  margin-top: 5px;
  text-align: center;
}
.content-number-number {
  font-size: 28px;
  color: #333333;
  letter-spacing: 0;
  line-height: 40px;
}
.paystyle-text {
  width: 100%;
  height: 21px;
  line-height: 21px;
  margin-top: 42px;
  margin-left: 5.6%;
}
.weixin {
  width: 100%;
  height: 56px;
  margin-top: 8px;
  display: flex;
}
.imgbox {
  width: 7%;
  height: 24px;
  margin-top: 15px;
  margin-left: 5.6%;
}
.paymentStyle-img {
  width: 24px;
  height: 24px;
}

.paymentStyle-text {
  width: 40%;
  height: 50px;
  line-height: 56px;
  margin-left: 1.9%;
  font-size: 15px;
  color: #666666;
  letter-spacing: 0;
}
.paymentStyle-check {
  position: fixed;
  right: 10%;
  line-height: 56px;
  text-align: center;
  padding-top: 18px;
}
.commitPay {
  width: 94%;
  height: 48px;
  margin-left: 3%;
  margin-top: 5%;
  background-color: #4d95f4;
  font-size: 19px;
  color: #ffffff;
  letter-spacing: 0;
  line-height: 26px;
  border-radius: 6px;
}
</style>
